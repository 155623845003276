import img from '../../assets/images/bupg.jpg'
import {useAuthStore} from "../../store/user-store";
import {useEffect} from "react";

export default function SliderComponent() {
    const authStore = useAuthStore()


    return (
        <section className={'container '}>
            <div className="row">
                <div className="col-md-12">
                    <div className="col-12 text-center">

                        <div id="mainSlider" className="carousel slide" data-bs-ride="carousel" >
                            <div className="carousel-inner slider-shadow">
                                {authStore.settings?.sliders?.length > 0 ?
                                    authStore.settings?.sliders?.map((el, index) => {
                                        return <div key={index}
                                                    className={`carousel-item  ${index === 0 ? 'active' : ''}`}
                                                    style={{backgroundImage: `url(${el})`}}/>
                                    })
                                    : <div className="carousel-item  active" style={{background:' linear-gradient(to right, var(--secondary), var(--primary-transparent), var(--dark)) no-repeat fixed'}}/>}


                            </div>
                           {/* <div className="carousel-indicators">
                                <button type="button" data-bs-target="#mainSlider" data-bs-slide-to="0"
                                        className="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#mainSlider" data-bs-slide-to="1"
                                        aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#mainSlider" data-bs-slide-to="2"
                                        aria-label="Slide 3"></button>
                            </div>*/}

                            <button className="carousel-control-prev " type="button" data-bs-target="#mainSlider"
                                    data-bs-slide="prev">
                              <span className="carousel-control-prev-icon " aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next " type="button" data-bs-target="#mainSlider"
                                    data-bs-slide="next">
                                <span className="carousel-control-next-icon " aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>

                        </div>

                    </div>
                </div>
            </div>
            {/* <div id="mainSlider" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner slider-shadow">
                    {authStore.settings?.sliders?.length>0?
                        authStore.settings?.sliders?.map((el,index)=>{
                            return   <div key={index} className={`carousel-item rounded ${index === 0 ? 'active' : ''}`} style={{backgroundImage: `url(${el})`}}/>
                        })
                        :  <div className="carousel-item rounded active" style={{backgroundImage: `url(${img})`}}/> }


                </div>
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#mainSlider" data-bs-slide-to="0"
                            className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#mainSlider" data-bs-slide-to="1"
                            aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#mainSlider" data-bs-slide-to="2"
                            aria-label="Slide 3"></button>
                </div>

                <button className="carousel-control-prev" type="button" data-bs-target="#mainSlider"
                        data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#mainSlider"
                        data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>

            </div>*/}
        </section>
    )
}